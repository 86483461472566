import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import PubSub from "pubsub-js";
import SlickSlider from "react-slick";
// import SlideImage from "./SlideImage";
import Img from "gatsby-image";
import clsx from "clsx";
import { WrapperContext } from "../Layout";
import CursorPrevNextText from "../ui/CursorPrevNextText";
import SlideVideo from "./SlideVideo";

const SlideSingle = ({ input }) => (
  <div className='slide slide--single '>
    <figure>
      {/* {!input.fluid && <pre>{JSON.stringify(input)}</pre>} */}
      {input.fluid && (
        <Img
          fluid={input.fluid}
          alt={input.alt}
          imgStyle={{
            objectFit: input.fluid?.aspectRatio > 1 ? "cover" : "cover",
            objectPosition: "50% 50%",
          }}
          className={clsx(
            "h-main",
            input.fluid?.aspectRatio > 1 ? "is-landscape" : "is-portait"
          )}
        />
      )}
    </figure>
  </div>
);

// const SlideVideo = ({ input }) => (
//   <div className='slide slide--video '>
//     <div className='h-main'>
//       <VideoPlayer url={input.video.url} poster={input.image.url} />
//     </div>
//   </div>
// );

const SlideDuo = ({ input }) => (
  <div className='slide slide--duo '>
    <div className='flex flex-col md:flex-row'>
      <figure className='w-full md:w-6/12'>
        <Img
          fluid={input.image.fluid}
          alt={input.image.alt}
          imgStyle={{
            objectFit: input.image.fluid.aspectRation > 1 ? "cover" : "cover",
            objectPosition: "50% 50%",
          }}
          className='h-main'
        />
      </figure>
      <figure className='w-full md:w-6/12 hidden-sm'>
        <Img
          fluid={input.image2.fluid}
          alt={input.image2.alt}
          imgStyle={{
            objectFit: input.image2.fluid.aspectRation > 1 ? "cover" : "cover",
            objectPosition: "50% 50%",
          }}
          className='h-main'
        />
      </figure>
    </div>
    {/* <pre>{JSON.stringify(input)}</pre> */}
  </div>
);

const SliderImage = ({ input, showPanignation = true }) => {
  const { isMobile } = useContext(WrapperContext);
  const [current, setCurrent] = useState(1);
  const [length, setLength] = useState(input.length || 0);

  const containerRef = useRef();
  const sliderRef = useRef();

  let arr = [];
  input.forEach((el) => {
    arr.push(el.image);
    // console.log(el.video.url);
    if (el.image2 && el.image2.url) arr.push(el.image2);
    if (el.video && el.video.url !== "")
      arr.push({ video: el.video.url, poster: el.image.url });
  });

  // console.log(input);
  // console.log(arr);

  useEffect(() => {
    // if (window.innerWidth < 768 && "ontouchstart" in window) setIsMobile(true);
    if (isMobile) {
      // alert(arr.length);
      setLength(arr.length);
    }
    document.addEventListener("keydown", _onKey);
  }, []);

  useEffect(() => {
    PubSub.publish("SLIDER_INDEX", {
      current: current,
      length: length,
    });

    // const currentSlide = containerRef.current.querySelector(
    //   `.slick-slide[data-index='${current - 1}'] .slide`
    // );
    // if (currentSlide.classList.contains("slide--video"))
    //   console.log(currentSlide);
  }, [current]);

  const _onKey = (e) => {
    // console.log(e.keyCode)
    switch (e.keyCode) {
      case 37:
        sliderRef.current.slickPrev();
        break;
      case 39:
        sliderRef.current.slickNext();
        break;
    }
  };
  // const _onEdge = () => {};
  // const _sliderBeforeChange = (oldIndex, newIndex) => {};
  const _sliderAfterChange = (index) => {
    setCurrent(index + 1);
  };

  const settings = {
    fade: false,
    dots: false,
    // speed: isMobile ? 250 : 500,
    speed: 0,
    swipeToSlide: true,
    cssEase: isMobile ? "ease" : "cubic-bezier(0.53, 0, 0.36, 1)",
    afterChange: _sliderAfterChange,
    prevArrow: <CursorPrevNextText label='prev' left='0%' />,
    nextArrow: <CursorPrevNextText label='next' left='67%' />,
  };

  const inputNotNull = input.filter((el) => el.image.url);

  return (
    <div className='slider' ref={containerRef}>
      <SlickSlider ref={sliderRef} {...settings}>
        {/* {isMobile &&
          arr.map((image, i) => <SlideSingle input={image} key={i} />)} */}

        {isMobile &&
          arr.map((item, i) => (
            <Fragment key={i}>
              {item.video ? (
                <SlideVideo
                  input={{
                    video: item.video,
                    image: item.poster,
                    autoplay: false,
                  }}
                />
              ) : (
                <SlideSingle input={item} />
              )}
            </Fragment>
          ))}

        {!isMobile &&
          inputNotNull.map(({ image, image2, video }, i) => (
            <Fragment key={i}>
              {video && video.url ? (
                <SlideVideo
                  input={{
                    video: video.url,
                    image: image.url,
                    autoplay: current - 1 === i,
                  }}
                />
              ) : image2 && image2.url ? (
                <SlideDuo input={{ image, image2 }} />
              ) : (
                <SlideSingle input={image} />
              )}
            </Fragment>
          ))}
      </SlickSlider>
      <div className='footer p-md pb-0 md:py-sm md:px-0 flex justify-between '>
        {showPanignation && (
          <div className='slider-pagination '>{`${current}/${length}`}</div>
        )}

        <div className='sm-only mobile-controls '>
          <div className='flex'>
            <button onClick={() => sliderRef.current.slickPrev()}>
              previous
            </button>
            <div className='px-xs'>/</div>
            <button onClick={() => sliderRef.current.slickNext()}>next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderImage;
