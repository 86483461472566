import React, { useContext } from "react";
import { RichText } from "prismic-reactjs";
// import Img from "gatsby-image";
import SliderImage from "./slices/SliderImage";
import ScrollerImage from "./slices/ScrollerImage";
import { WrapperContext } from "./Layout";

const ProjectArticle = ({ input }) => {
  // console.log(input);
  const { isMobile } = useContext(WrapperContext);
  const { title, texte, images } = input;

  return (
    <article className='project mb-md- '>
      <div className='row'>
        <div className='col-md-10 col-xs-12'>
          {images && (
            <div className='images '>
              {/* {isMobile && <ScrollerImage input={images} />}
              {!isMobile && <SliderImage input={images} />} */}
              <SliderImage input={images} />
            </div>
          )}
        </div>

        <div className='col-md-2 col-xs-12'>
          <div className='mb-md- h-full content'>
            <div className='sticky top-md p-md md:p-0'>
              <h1 className='text-md pb-xs mb-xs'>{title.text}</h1>
              <div className='texte'>{RichText.render(texte.raw)}</div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ProjectArticle;
