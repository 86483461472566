import React from "react";
import VideoPlayer from "../ui/VideoPlayer";

const SlideVideo = ({ input }) => (
  <div className='slide slide--video h-full'>
    <div className='h-main'>
      {/* <pre>{JSON.stringify(input.autoplay)}</pre> */}
      <VideoPlayer
        url={input.video}
        poster={input.image}
        autoplay={input.autoplay || false}
      />
    </div>
  </div>
);

export default SlideVideo;
