import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const Wrapper = styled.div`
  .react-player__shadow {
    background: transparent !important;
  }
  .react-player__preview {
    @media all and (max-width: 768px) {
      background: white !important;
      .react-player__play-icon {
        color: white;
      }
    }
  }
  .react-player__play-icon {
    border: none !important;
    font-size: var(--text-lg);
    &:before {
      content: "PLAY";
      color: white;
      mix-blend-mode: difference;
    }
  }
  video {
    object-fit: cover;
  }
`;

const VideoPlayer = ({ url, poster, autoplay = false }) => {
  const [mute, setMute] = useState(true);
  const [playing, setPlaying] = useState(autoplay);
  // const [status, setStatus] = useState({
  //   mute: true,
  //   playing: autoplay,
  // });
  // console.log(url, poster, autoplay);
  const player = useRef();

  const config = {
    youtube: {
      playerVars: {
        controls: 0,
        disablekb: 1,
        enablejsapi: 1,
        iv_load_policy: 3,
        modestbranding: 1,
        showinfo: 0,
        cc_load_policy: 0,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        origin: "https://sisto-studios.netlify.app/",
      },
    },
    vimeo: {
      title: "false",
      background: true,
      controls: false,
    },
  };

  const _onReady = () => {};
  const _onEnded = () => {};
  const _togglePlayState = () => {
    // console.log("_togglePlayState");
    setPlaying(!playing);
  };
  // console.log(autoplay);
  return (
    <Wrapper
      className='video-player h-full'
      ref={player}
      onClick={_togglePlayState}>
      <ReactPlayer
        className={`player  h-full `}
        url={url}
        // url="https://vimeo.com/78429727"
        // playing={status.playing}
        playing={playing}
        loop={true}
        muted={mute}
        light={autoplay === false ? poster : false}
        playsinline
        config={config}
        width='100%'
        height='100%'
        onReady={_onReady}
        onEnded={_onEnded}
      />
    </Wrapper>
  );
};

export default VideoPlayer;
